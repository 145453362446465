<template>
  <div class="layout-padding">
    <PageTitle
      class="my-8"
      :title="text?.title"
      :childOne="text?.title"
      routeOne="/founder"
      type="withBg"
    />
    <div class="grid grid-cols-2 gap-x-10 md:row-start-2 mt-12">
      <div
        v-if="text"
        class="col-span-2 md:col-span-1 order-last md:order-first"
      >
        <div class="lora-bold text-size-16 mb-4">
          {{ text.subtitle }}
        </div>
        <div class="about-content">
          {{ text.description }}
        </div>
      </div>
      <div
        class="col-span-2 md:col-span-1 order-first md:order-last mb-12 md:mb-0"
      >
        <div class="relative">
          <swiper
            :slides-per-view="1"
            :space-between="20"
            :effect="'fade'"
            :pagination="pagination"
            :loop="true"
            :autoplay='autoplay'
          >
            <swiper-slide v-for="(image, index) in images" :key="index">
              <div
                :style="`background: url(${image})`"
                class="founder-slide-image"
              />
            </swiper-slide>
          </swiper>
          <div class="whoweare-pagination"></div>
        </div>
      </div>
    </div>
    <div class="section-title lora-bold mt-16">{{ content.readAlso }}</div>
    <div class="grid grid-cols-2 gap-x-10 md:row-start-2 mb-16">
      <div class="col-span-2 md:col-span-1">
        <ReadAlsoCard
          :img="who.image"
          :title="who.title"
          :description="who.subtitle"
          :to="'/whoweare'"
          type="one"
        />
      </div>
      <div class="col-span-2 md:col-span-1">
        <ReadAlsoCard
          :img="story.image"
          :title="story.title"
          :description="story.subtitle"
          :to="'/ourStory'"
          type="two"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination, EffectFade ,Autoplay } from "swiper";
import ReadAlsoCard from "/src/components/ui/About/ReadAlsoCard.vue";

import "swiper/swiper-bundle.css";
import "swiper/components/navigation/navigation.min.css";

SwiperCore.use([Navigation, Pagination, EffectFade ,Autoplay]);

export default {
  components: {
    Swiper,
    SwiperSlide,
    ReadAlsoCard,
  },
  inject: ["content"],
  data() {
    return {
      navigation: {
        nextEl: "#nextItem",
        prevEl: "#prevItem",
      },
       autoplay: {
        delay: 2000,
      },
      breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        567: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        992: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1500: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1800: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      },
      pagination: {
        el: ".whoweare-pagination",
        type: "bullets",
        clickable: true,
        renderBullet: function () {
          return `<span class="dot swiper-pagination-bullet" style="background: #fff !important; margin-inline-start:10px;width:10px;height:2px;margin-top:30px" ></span>`;
        },
      },
    };
  },
  computed: {
    text() {
      return this.$store.getters["pages/text"]("founder");
    },
    images() {
      let images = [];

      if (this.text?.image) images.push(this.text?.image);

      if (this.text?.images) {
        for (let i = 0; i < this.text?.images?.length; i++) {
          images.push(this.text?.images[i].url);
        }
      }

      return images;
    },
    story() {
      return this.$store.getters["pages/text"]("story");
    },
    who() {
      return this.$store.getters["pages/text"]("who");
    },
  },
};
</script>


<style  >
.whoweare-pagination {
  position: absolute;
  bottom: 30px;
  left: 0vw;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
}
.whoweare-pagination .swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  padding: 20px 4vw;
  display: flex;
}
.whoweare-pagination .swiper-pagination-bullet {
  border-radius: 0;
  height: 3px;
  width: 30px !important;
  background: #302b87 !important;
  opacity: 0.5 !important;
}
.whoweare-pagination .swiper-pagination-bullet-active {
  background: #302b87;
  opacity: 1 !important;
}
@media (max-width: 567px) {
  .whoweare-pagination .swiper-pagination-bullet {
    width: 30px !important;
  }
}
</style>    